import React, { forwardRef, useCallback, useImperativeHandle, useRef } from "react";
import type { GlobalOptions, CreateTypes, Options } from "canvas-confetti";
import confetti from "canvas-confetti";

export type ConfettiRef = {
  fire: (options?: Options) => void;
} | null;

type Props = React.ComponentPropsWithRef<"canvas"> & {
  options?: Options;
  globalOptions?: GlobalOptions;
};

const Confetti = forwardRef<ConfettiRef, Props>((props, ref) => {
  const {
    options,
    globalOptions = { resize: true, useWorker: true },
    style,
    ...rest
  } = props;

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const instanceRef = useRef<CreateTypes | null>(null);

  const getInstance = useCallback(() => {
    if (!instanceRef.current && canvasRef.current) {
      instanceRef.current = confetti.create(canvasRef.current, globalOptions);
    }
    return instanceRef.current;
  }, [globalOptions]);

  const fire = useCallback((opts: Options = {}) => {
    const instance = getInstance();
    if (instance) {
      instance({
        particleCount: 150,
        spread: 100,
        origin: { y: 0.6 },
        colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
        ...options,
        ...opts,
      });
    }
  }, [getInstance, options]);

  useImperativeHandle(ref, () => ({
    fire
  }), [fire]);

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: 'fixed',
        inset: 0,
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
        zIndex: 50,
        ...style,
      }}
      {...rest}
    />
  );
});

Confetti.displayName = "Confetti";

export default Confetti; 