export async function subscribeToWaitlist(email: string) {
  try {
    const response = await fetch('https://playlist.rest/api/waitlist', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to subscribe to waitlist');
    }

    const data = await response.json();
    return data;
  } catch (error: unknown) {
    console.error('Error in waitlist subscription:', error);
    throw error;
  }
} 