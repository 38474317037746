import React from 'react';
import { Link } from 'react-router-dom';

export default function Header() {
  return (
    <header className="border-b border-gray-800 backdrop-blur-sm bg-gray-900/50">
      <div className="container mx-auto px-4">
        <div className="max-w-6xl mx-auto">
          <div className="max-w-xl py-3">
            <Link to="/" className="inline-flex items-center gap-3 group">
              <img 
                src="/android-chrome-512x512.png" 
                alt="playlist.rest logo" 
                className="w-10 h-10"
              />
              <h1 className="text-3xl font-bold">
                <span className="bg-gradient-to-r from-white via-purple-200 to-purple-400 text-transparent bg-clip-text bg-300% animate-gradient">
                  playlist.rest
                </span>
              </h1>
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
}