import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Music, Heart, Share2, Lock, Download, CheckCircle, Clock, ArrowRight, AlertCircle, Crown, Flower, GraduationCap, Users, Star, Rocket, Loader2 } from 'lucide-react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { subscribeToWaitlist } from '../services/api';
import ShimmerButton from '../components/ShimmerButton';
import Confetti, { ConfettiRef } from '../components/Confetti';
import confetti from 'canvas-confetti';
import { AnimatedGridPattern } from '../components/AnimatedGridPattern';
import IconCloud from '../components/IconCloud';

const MAX_NAME_LENGTH = 50;

export default function Home() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const confettiRef = useRef<ConfettiRef>(null);
  const formRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const validateEmail = (value: string): string => {
    if (!value.trim()) {
      return 'Email is required';
    }

    if (value.length > MAX_NAME_LENGTH) {
      return `Email must be ${MAX_NAME_LENGTH} characters or less`;
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(value)) {
      return 'Please enter a valid email address';
    }

    return '';
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const validationError = validateEmail(email);
    if (validationError) {
      setError(validationError);
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      await subscribeToWaitlist(email);
      setIsSuccess(true);
      setEmail('');
      
      // Calculate the position for the confetti
      if (formRef.current) {
        const rect = formRef.current.getBoundingClientRect();
        const x = (rect.left + rect.width / 2) / window.innerWidth;
        const y = rect.bottom / window.innerHeight;
        
        // Create various musical emoji shapes
        const scalar = 3;
        const musicNote = confetti.shapeFromText({ text: "🎵", scalar });
        const saxophone = confetti.shapeFromText({ text: "🎷", scalar });
        const guitar = confetti.shapeFromText({ text: "🎸", scalar });
        const musicalNotes = confetti.shapeFromText({ text: "🎼", scalar });

        // Default settings for all bursts
        const defaults = {
          ticks: 100,
          gravity: 0.3,
          decay: 0.95,
          startVelocity: 20,
          scalar,
          origin: { x, y }
        };

        // Fire a combination of musical emojis
        const shoot = () => {
          // First burst with music note and sax
          confettiRef.current?.fire({
            ...defaults,
            shapes: [musicNote, saxophone],
            particleCount: 8,
            spread: 60,
            colors: ['#ffffff'],
          });

          // Second burst with guitar and musical notes
          confettiRef.current?.fire({
            ...defaults,
            shapes: [guitar, musicalNotes],
            particleCount: 8,
            spread: 80,
            colors: ['#ffffff'],
          });

          // Sparkles
          confettiRef.current?.fire({
            ...defaults,
            particleCount: 15,
            shapes: ["circle"],
            scalar: scalar / 4,
            spread: 100,
            colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
          });
        };

        // Grand finale burst
        const shootFinale = () => {
          // Center burst with all emojis
          confettiRef.current?.fire({
            ...defaults,
            shapes: [musicNote, saxophone, guitar, musicalNotes],
            particleCount: 20,
            spread: 120,
            startVelocity: 30,
            colors: ['#ffffff'],
          });

          // Left side burst
          confettiRef.current?.fire({
            ...defaults,
            shapes: [musicNote, guitar],
            particleCount: 10,
            spread: 80,
            origin: { x: x - 0.2, y },
            startVelocity: 25,
          });

          // Right side burst
          confettiRef.current?.fire({
            ...defaults,
            shapes: [saxophone, musicalNotes],
            particleCount: 10,
            spread: 80,
            origin: { x: x + 0.2, y },
            startVelocity: 25,
          });

          // Lots of sparkles
          confettiRef.current?.fire({
            ...defaults,
            particleCount: 30,
            spread: 180,
            shapes: ["circle"],
            scalar: scalar / 4,
            startVelocity: 15,
            colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
          });
        };

        // Fire sequence with grand finale
        shoot();
        setTimeout(shoot, 300);
        setTimeout(shootFinale, 600);
      }
    } catch (err) {
      console.error('Subscription error:', err);
      setError('Something went wrong. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 text-gray-100">
      <Header />
      
      {/* Hero Section with Form */}
      <div className="relative">
        {/* Background layers */}
        <div className="absolute inset-0 bg-purple-600/10 backdrop-blur-3xl">
          <AnimatedGridPattern
            className="fill-purple-500/5 stroke-purple-500/5"
            width={40}
            height={40}
            strokeDasharray="8 8"
            numSquares={60}
            maxOpacity={0.15}
            duration={8}
          />
        </div>
        {/* White gradient overlay */}
        <div className="absolute inset-0 bg-gradient-to-b from-white/10 via-white/5 to-transparent pointer-events-none"></div>
        
        <Confetti ref={confettiRef} />
        <div className="relative container mx-auto px-4 pt-16 pb-24">
          <div className="max-w-6xl mx-auto grid md:grid-cols-2 gap-12 items-center">
            <div className="max-w-xl space-y-8">
              <div className="inline-flex items-center px-3 py-1 rounded-full bg-purple-500/10 text-purple-300 text-sm">
                <Rocket className="w-4 h-4 mr-2 animate-pulse" />
                Shipping Soon
              </div>
              <div className="space-y-4">
                <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold leading-[1.15] tracking-normal bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent">
                  Make Shared Playlists.<br />
                  Super Easily.
                </h1>
                <p className="text-xl text-gray-400 leading-relaxed">
                  Invite everyone to add their favorite songs, no accounts needed. Works with Spotify, Apple Music, or any service.
                </p>
              </div>
              <div className="flex flex-wrap gap-4 text-sm text-gray-400">
                <div className="flex items-center gap-2">
                  <Share2 className="w-4 h-4 text-purple-400" />
                  <span>Easy Collaboration</span>
                </div>
                <div className="flex items-center gap-2">
                  <Lock className="w-4 h-4 text-purple-400" />
                  <span>Full Control</span>
                </div>
                <div className="flex items-center gap-2">
                  <Download className="w-4 h-4 text-purple-400" />
                  <span>Use Anywhere</span>
                </div>
              </div>
            </div>

            <div className="relative" ref={formRef}>
              <div className="absolute inset-0 bg-gradient-to-r from-purple-500/30 to-pink-500/30 rounded-2xl blur-2xl"></div>
              <div className="relative bg-gray-800/80 backdrop-blur-sm rounded-2xl p-8 shadow-2xl">
                <h2 className="text-2xl font-bold mb-2">Get Early Access</h2>
                <p className="text-gray-400 mb-6">Be the first to know when we launch. Get exclusive early access.</p>
                
                {isSuccess ? (
                  <div className="text-center py-4">
                    <CheckCircle className="w-12 h-12 text-green-400 mx-auto mb-4" />
                    <h3 className="text-xl font-semibold mb-2">You're on the list!</h3>
                    <p className="text-gray-400">We'll notify you when we launch. Check your email for confirmation.</p>
                  </div>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <div className="mb-6">
                      <label className="block text-sm font-medium mb-2">
                        Email Address
                      </label>
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setError('');
                        }}
                        className={`w-full bg-gray-700/50 rounded-lg px-4 py-3 text-lg focus:ring-2 focus:ring-purple-400 focus:outline-none placeholder-gray-500 ${
                          error ? 'border border-red-500' : ''
                        }`}
                        placeholder="you@example.com"
                        disabled={isLoading}
                      />
                      {error && (
                        <div className="flex items-center gap-2 mt-2 text-red-400 text-sm">
                          <AlertCircle className="w-4 h-4" />
                          <span>{error}</span>
                        </div>
                      )}
                    </div>
                    <ShimmerButton
                      type="submit"
                      disabled={isLoading}
                      shimmerColor="rgba(255, 255, 255, 0.4)"
                      className="w-full text-lg font-bold"
                    >
                      <div className="relative flex items-center justify-center gap-2">
                        {isLoading ? (
                          <>
                            <Loader2 className="w-5 h-5 animate-spin" />
                            <span>Joining Waitlist...</span>
                          </>
                        ) : (
                          <>
                            <span>Notify Me</span>
                            <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
                          </>
                        )}
                      </div>
                    </ShimmerButton>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="container mx-auto px-4 py-16">
        <div className="text-center mb-12">
          <h2 className="text-4xl md:text-5xl font-bold mb-3">How It Works</h2>
          <p className="text-xl text-gray-400 max-w-2xl mx-auto">
            Create meaningful playlists together, without the hassle
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8 max-w-6xl mx-auto">
          <div className="relative group">
            <div className="absolute inset-0 bg-gradient-to-r from-purple-500/10 to-pink-500/10 rounded-2xl blur group-hover:blur-xl transition-all duration-300"></div>
            <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-xl p-6 h-full border border-gray-700/50 hover:border-purple-500/50 transition-colors duration-300">
              <Share2 className="w-12 h-12 text-purple-400 mb-4" />
              <h3 className="text-xl font-semibold mb-2">Easy Collaboration</h3>
              <p className="text-gray-400">Share a simple link - anyone can add songs from any music service, no login required.</p>
            </div>
          </div>

          <div className="relative group">
            <div className="absolute inset-0 bg-gradient-to-r from-purple-500/10 to-pink-500/10 rounded-2xl blur group-hover:blur-xl transition-all duration-300"></div>
            <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-xl p-6 h-full border border-gray-700/50 hover:border-purple-500/50 transition-colors duration-300">
              <Lock className="w-12 h-12 text-purple-400 mb-4" />
              <h3 className="text-xl font-semibold mb-2">Full Control</h3>
              <p className="text-gray-400">The playlist owners reviews and approve submissions to ensure your playlist perfectly represents your story.</p>
            </div>
          </div>
          
          <div className="relative group">
            <div className="absolute inset-0 bg-gradient-to-r from-purple-500/10 to-pink-500/10 rounded-2xl blur group-hover:blur-xl transition-all duration-300"></div>
            <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-xl p-6 h-full border border-gray-700/50 hover:border-purple-500/50 transition-colors duration-300">
              <Download className="w-12 h-12 text-purple-400 mb-4" />
              <h3 className="text-xl font-semibold mb-2">Use Anywhere</h3>
              <p className="text-gray-400">Export your playlist for Spotify, Apple Music, as a PDF, CSV or any format you need.</p>
            </div>
          </div>
        </div>
      </div>

      {/* Use Cases Section */}
      <div className="container mx-auto px-4 py-16">
        <div className="text-center mb-12">
          <h2 className="text-4xl md:text-5xl font-bold mb-3">Perfect For Every Occasion</h2>
          <p className="text-xl text-gray-400 max-w-2xl mx-auto">
            Create shared playlists for life's most important moments
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {/* Wedding Card */}
          <div className="group relative overflow-hidden rounded-2xl">
            <div className="absolute inset-0 bg-gradient-to-br from-purple-500/20 to-pink-500/20 group-hover:from-purple-500/30 group-hover:to-pink-500/30 transition-all duration-300"></div>
            <div className="relative p-8 backdrop-blur-sm bg-gray-800/40 h-full border border-gray-700/50 group-hover:border-purple-500/50 transition-all duration-300">
              <Crown className="w-10 h-10 text-purple-400 mb-4 transform group-hover:scale-110 transition-transform duration-300" />
              <h3 className="text-xl font-semibold mb-3">Wedding Celebrations</h3>
              <p className="text-gray-400">Perfect wedding playlists, from ceremony to reception, built together with loved ones.</p>
            </div>
          </div>

          {/* Memorial Card */}
          <div className="group relative overflow-hidden rounded-2xl">
            <div className="absolute inset-0 bg-gradient-to-br from-purple-500/20 to-pink-500/20 group-hover:from-purple-500/30 group-hover:to-pink-500/30 transition-all duration-300"></div>
            <div className="relative p-8 backdrop-blur-sm bg-gray-800/40 h-full border border-gray-700/50 group-hover:border-purple-500/50 transition-all duration-300">
              <Flower className="w-10 h-10 text-purple-400 mb-4 transform group-hover:scale-110 transition-transform duration-300" />
              <h3 className="text-xl font-semibold mb-3">Memorial Services</h3>
              <p className="text-gray-400">Honor memories together with collaborative memorial service and celebration of life playlists.</p>
            </div>
          </div>

          {/* School Dances Card */}
          <div className="group relative overflow-hidden rounded-2xl">
            <div className="absolute inset-0 bg-gradient-to-br from-purple-500/20 to-pink-500/20 group-hover:from-purple-500/30 group-hover:to-pink-500/30 transition-all duration-300"></div>
            <div className="relative p-8 backdrop-blur-sm bg-gray-800/40 h-full border border-gray-700/50 group-hover:border-purple-500/50 transition-all duration-300">
              <GraduationCap className="w-10 h-10 text-purple-400 mb-4 transform group-hover:scale-110 transition-transform duration-300" />
              <h3 className="text-xl font-semibold mb-3">School Dances</h3>
              <p className="text-gray-400">Make school dances special with collaborative prom & homecoming playlists.</p>
            </div>
          </div>

          {/* Party Card */}
          <div className="group relative overflow-hidden rounded-2xl">
            <div className="absolute inset-0 bg-gradient-to-br from-purple-500/20 to-pink-500/20 group-hover:from-purple-500/30 group-hover:to-pink-500/30 transition-all duration-300"></div>
            <div className="relative p-8 backdrop-blur-sm bg-gray-800/40 h-full border border-gray-700/50 group-hover:border-purple-500/50 transition-all duration-300">
              <Music className="w-10 h-10 text-purple-400 mb-4 transform group-hover:scale-110 transition-transform duration-300" />
              <h3 className="text-xl font-semibold mb-3">Party Soundtracks</h3>
              <p className="text-gray-400">Build the ultimate party soundtrack with input from all your guests.</p>
            </div>
          </div>

          {/* Family Reunions Card */}
          <div className="group relative overflow-hidden rounded-2xl">
            <div className="absolute inset-0 bg-gradient-to-br from-purple-500/20 to-pink-500/20 group-hover:from-purple-500/30 group-hover:to-pink-500/30 transition-all duration-300"></div>
            <div className="relative p-8 backdrop-blur-sm bg-gray-800/40 h-full border border-gray-700/50 group-hover:border-purple-500/50 transition-all duration-300">
              <Users className="w-10 h-10 text-purple-400 mb-4 transform group-hover:scale-110 transition-transform duration-300" />
              <h3 className="text-xl font-semibold mb-3">Family Gatherings</h3>
              <p className="text-gray-400">Bring generations together with family reunion & gathering playlists that everyone contributes to.</p>
            </div>
          </div>

          {/* Celebrations Card */}
          <div className="group relative overflow-hidden rounded-2xl">
            <div className="absolute inset-0 bg-gradient-to-br from-purple-500/20 to-pink-500/20 group-hover:from-purple-500/30 group-hover:to-pink-500/30 transition-all duration-300"></div>
            <div className="relative p-8 backdrop-blur-sm bg-gray-800/40 h-full border border-gray-700/50 group-hover:border-purple-500/50 transition-all duration-300">
              <Star className="w-10 h-10 text-purple-400 mb-4 transform group-hover:scale-110 transition-transform duration-300" />
              <h3 className="text-xl font-semibold mb-3">Life Celebrations</h3>
              <p className="text-gray-400">Create meaningful playlists for birthdays, anniversaries, and other special milestones.</p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}